import React, {useState} from 'react';
import MetadataValueIcon from "../../common/MetadataValueIcon";
import PropTypes from "prop-types";
import {hasAccess} from "../../common/helper";
import ViewFolderLinkButton from "../link/ViewFolderLinkButton";
import EditFolderButton from "./EditFolderButton";
import DashboardButton from "../../reporting/DashboardButton";
import EmailButton from "../EmailButton";
import ActionButtons from "../workflow/ActionButtons";
import MoveAllDocsToCaseButton from "../MoveAllDocsToCaseButton";
import ShareButton from "../../collaborate/ShareButton";
import AuditButton from "../../audit/AuditButton";
import RelatedCaseSearchButtons from "../relatedCaseSearch/RelatedCaseSearchButtons"
import AddFolderUserButton from "../security/AddFolderUserButton";
import ViewUrlSearchLinkButton from "../../search/url/ViewUrlSearchLinkButton";

function FolderHeader (props) {

   //console.log ('FolderHeader props=', props)

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('FolderHeader props.folderDetails=' , props.folderDetails);

    const [mountActionButtons, setMountActionButtons] = useState(true);
    //const [folderDetails, setFolderDetails] = useState(props.folderDetails);

    function unmountActionButtons() {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('FolderHeader unmountActionButtons props.folderDetails=' , props.folderDetails);
        setMountActionButtons(false)
    }

    function remountActionButtons() {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('FolderHeader remountActionButton props.folderDetails=' , props.folderDetails);
        setMountActionButtons(true)
    }

    let header = "";
    let headerIcons = [];
    let folderHeaderConfig = props.folderHeaderConfig;
    let folderDetails = props.folderDetails;

    for (let i = 0; i < folderHeaderConfig.length; i++) {
        if (folderHeaderConfig[i].displayAsIcon) {
            let iconConfig = {};
            if (props.metadataConfig[folderHeaderConfig[i].templateKey]) {
                if (props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey]) {
                    if (props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey]) {
                        if (props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey].iconConfig) {
                            iconConfig = props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey].iconConfig;
                        }
                    }
                }
            }
            headerIcons.push(
                <MetadataValueIcon
                    iconConfig={iconConfig}
                    key={'icon~'+ folderHeaderConfig[i].templateKey + '~' + folderHeaderConfig[i].metadataKey}
                    val={folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey]}/>);
        } else {
            let separator = (header === "") ? "" : " - ";
            header =
                folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey] &&
                folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey] !== "" ?
                    header + separator + folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey] :
                    header
        }
    }

    //Append icons for any folder actions enabled
    if (props.showFolderActions) {
        if (props.actionsConfig.viewLinkFolder && props.actionsConfig.viewLinkFolder.enabled && hasAccess(props.actionsConfig.viewLinkFolder, props.userDetails.userRoles)) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <ViewFolderLinkButton
                        folderId={props.folderDetails.id}
                        actionConfig={props.actionsConfig.viewLinkFolder}/>
                </div>)
        }

        if (props.actionsConfig.urlSearch && props.actionsConfig.urlSearch.button && props.actionsConfig.urlSearch.button.enabled && hasAccess(props.actionsConfig.urlSearch.button, props.userDetails.userRoles)) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <ViewUrlSearchLinkButton
                        folderDetails={props.folderDetails}
                        actionConfig={props.actionsConfig.urlSearch}/>
                </div>)
        }

        if (props.actionsConfig.dashboardFolder && props.actionsConfig.dashboardFolder.enabled && hasAccess(props.actionsConfig.dashboardFolder, props.userDetails.userRoles)) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <DashboardButton actionConfig={props.actionsConfig.dashboardFolder}/>
                </div>
                )
        }

        if (props.actionsConfig.email && props.actionsConfig.email.enabled && hasAccess(props.actionsConfig.email, props.userDetails.userRoles)) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <EmailButton
                        actionConfig={props.actionsConfig.email}
                        folderDetails={props.folderDetails}
                    />
                </div>
            )
        }

        if (props.actionsConfig.moveAllDocsToCase && props.actionsConfig.moveAllDocsToCase.enabled && hasAccess(props.actionsConfig.moveAllDocsToCase, props.userDetails.userRoles)) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <MoveAllDocsToCaseButton
                        actionConfig={props.actionsConfig.moveAllDocsToCase}
                        folderDetails={props.folderDetails}
                        userDetails={props.userDetails}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    />
                </div>
            )
        }

        if (props.actionsConfig.shareFolder && props.actionsConfig.shareFolder.enabled && hasAccess(props.actionsConfig.shareFolder, props.userDetails.userRoles)) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <ShareButton
                        boxId={props.folderDetails.id}
                        type={"folder"}
                        actionConfig={props.actionsConfig.shareFolder}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        userDetails={props.userDetails}
                    />
                </div>
            )
        }

        const addFolderUserConfig = props.actionsConfig &&  props.actionsConfig.addFolderUser;
        let showAddFolderUser = addFolderUserConfig && addFolderUserConfig.enabled && hasAccess(addFolderUserConfig, props.userDetails.userRoles)
        if (showAddFolderUser) {
            //also check for existence of folderGroupPrefix on folder details to cater for portals where we have more than one case type
            const prefixConfig = props.actionsConfig.addFolderUser.folderGroupPrefix;
            if (prefixConfig && prefixConfig.templateKey && prefixConfig.metadataKey){
                if ( ! props.folderDetails || !folderDetails[prefixConfig.templateKey +"~" + prefixConfig.metadataKey]) {
                    showAddFolderUser = false
                }
            }
        }

        if (showAddFolderUser) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <AddFolderUserButton
                        boxId={props.folderDetails.id}
                        actionConfig={props.actionsConfig.addFolderUser}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        userDetails={props.userDetails}
                        folderDetails={props.folderDetails}
                        remountFolderGroupMembersDisplay={props.remountFolderGroupMembersDisplay}
                    />
                </div>
            )
        }

        if (props.metadataHasChanged) {
            if (props.actionsConfig.editFolder.enabled) {
                if (hasAccess(props.actionsConfig.editFolder, props.userDetails.userRoles)) {
                    headerIcons.push(
                        <div style={{display: "inline"}}>
                            <EditFolderButton
                                folderDetails={props.folderDetails}
                                actionConfig={props.actionsConfig.editFolder}
                                userDetails={props.userDetails}
                                triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                metadataHasChanged={props.metadataHasChanged}
                                resetMetadataHasChanged={props.resetMetadataHasChanged}
                                addFolderConfig={props.addFolderConfig}
                                updateIsSaving={props.updateIsSaving}
                                searchConfig={props.searchConfig}
                                metadataConfig ={props.metadataConfig}
                                //unmountFolderContent={props.unmountFolderContent}
                            />
                        </div>)
                }
            }
        }

        if (props.actionsConfig.audit && props.actionsConfig.audit.case && props.actionsConfig.audit.case.enabled) {
            headerIcons.push(
                <div style={{display: "inline"}}>
                    <AuditButton
                        parentClasses={props.classes}
                        userDetails={props.userDetails}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        caseId={props.folderDetails.id}
                        type={"case"}
                    />
                </div>
            )

        }

        headerIcons.push(
            <div style={{display: "inline"}}>
                <RelatedCaseSearchButtons
                    parentClasses={props.classes}
                    userDetails={props.userDetails}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    folderDetails={props.folderDetails}
                    actionConfig={props.actionsConfig.relatedCaseSearches}
                    metadataConfig={props.metadataConfig}
                    optionsConfig={props.optionsConfig}
                />
            </div>
        )
    }



    if (header === "") {
        header = folderDetails.name
    }

    return (
        <React.Fragment>
            <div style={{display: "inline"}}>
                {header} {headerIcons}
                {
                    mountActionButtons &&
                    <ActionButtons
                        folderDetails={folderDetails}
                        userDetails={props.userDetails}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        updateFolderDetailsFromWorkflowAction={props.updateFolderDetailsFromWorkflowAction}
                        updateFolderDetails={props.updateFolderDetails}
                        workflowConfig={props.workflowConfig}
                        unmountComponent={unmountActionButtons}
                        remountComponent={remountActionButtons}
                    />
                }

            </div>
        </React.Fragment>)
}

FolderHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderHeaderConfig: PropTypes.array.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    showFolderActions: PropTypes.bool.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    metadataHasChanged: PropTypes.bool.isRequired,
    resetMetadataHasChanged: PropTypes.func.isRequired,
    updateFolderDetailsFromWorkflowAction: PropTypes.func.isRequired,
    updateFolderDetails: PropTypes.func,
    workflowConfig: PropTypes.array.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    updateIsSaving: PropTypes.func,
    searchConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    remountFolderGroupMembersDisplay: PropTypes.func.isRequired,
    unmountFolderContent: PropTypes.func.isRequired
}

export default (FolderHeader);